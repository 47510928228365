import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Link from '~components/Link';
import { Box, Button, Typography } from '@material-ui/core';
import RichText from '~components/RichText';
import logo from '~images/holidays/coupon/logo.svg';
import mobileLogo from '~images/holidays/coupon/mobilelogo.svg';
import leftCutInImg from '~images/holidays/coupon/left-cut-in.svg';
import rightCutInImg from '~images/holidays/coupon/right-cut-in.svg';
import mobileLeftImg from '~images/holidays/coupon/leftMobile.svg';
import mobilRighttImg from '~images/holidays/coupon/rightMobile.svg';
import dottedLineImg from '~images/holidays/coupon/dotted-line.png';
import dottedLineMobileImg from '~images/holidays/coupon/dotted-line-mobile.png';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#020928'
  },
  headerStyle: props => ({
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
      fontSize: '1.5rem',
      marginBottom: '1rem'
    },
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '70px',
    marginBottom: '1.8rem',
    color: props.text_color,
    backgroundColor: '#020928',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '117%'
  }),
  ticketContainer: props => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: props.background_color,
    padding: 0,
    margin: 0
  }),
  containerStyle: props => ({
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('xs')]: {
      width: '22.875rem',
      height: '7.3rem'
    },
    margin: 0,
    padding: 0,
    width: '1066px',
    height: '155px',
    alignItems: 'center',
    borderRadius: '13px',
    border: '1px solid #FFF;',
    backgroundColor: props.background_color,
    display: 'flex'
  }),
  innerContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      justifyContent: 'space-between'
    },
    display: 'flex',
    alignItems: 'center'
  },
  checkoutButton: props => ({
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      marginRight: '8%'
    },
    height: '63px',
    width: '10.6875rem',
    marginRight: '1.5rem',
    marginLeft: '2.5rem',
    color: props.cta_text_color,
    fontFamily: 'Inter',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '700',
    textTransform: 'none',
    lineHeight: '140%',
    borderRadius: '2.84375rem',
    border: '1px solid #001730',
    backgroundColor: props.cta_background_color,
    '&:hover': {
      backgroundColor: '#F6F6F6',
      textDecoration: 'none'
    }
  }),
  logoStyle: {
    [theme.breakpoints.down('xs')]: {
      marginRight: '1.3rem',
      marginLeft: '0.81rem'
    },
    marginRight: '3.06rem',
    marginLeft: '2.49rem'
  },
  offerContainer: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '14.5rem',
      marginLeft: '1.44rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '4%'
    },
    marginLeft: '3.17rem',
    paddingLeft: '0px',
    position: 'relative',
    width: '35.875rem'
  },
  offerStyle: props => ({
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
      fontSize: '1.125rem',
      marginBottom: '2px'
    },
    color: props.text_color,
    marginTop: '-3px',
    marginBottom: '0.75rem',
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.695rem'
  }),
  descriptionStyle: props => ({
    [theme.breakpoints.down('xs')]: {
      lineHeight: '130%',
      fontSize: '0.875rem'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline'
    },
    color: props.description_text_color,
    fontFamily: 'Inter',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    margin: '0px',
    padding: '0px'
  }),
  description: props => ({
    margin: '0px',
    padding: '0px',
    '& a': {
      color: props.description_text_color,
      textDecoration: 'underline'
    },
    '& > *': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
        lineHeight: '130%'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'inline'
      },
      color: props.description_text_color,
      ontFamily: 'Inter',
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '140%',
      margin: '0px',
      padding: '0px'
    }
  }),
  codeStyle: {
    color: '#317DC9',
    fontFamily: 'Inter',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '140%',
    display: 'inline',
    textDecoration: 'underline'
  },
  leftCutIn: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-20.5px'
    },
    marginLeft: '-34px'
  },
  rightCutIn: {
    [theme.breakpoints.down('xs')]: {
      marginRight: '14.5px'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: '-35px'
    }
  }
}));

const CouponComponent = props => {
  const { session } = useUserContext();
  const { isUserStateLoading, isAdmin, isSubscriber } = session;
  const {
    offer_title,
    offer_name,
    offer_description,
    coupon_code,
    cta_text,
    cta_url,
    background_color,
    text_color,
    description_text_color,
    cta_background_color,
    cta_text_color,
    subscription_level
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({
    background_color,
    text_color,
    cta_background_color,
    cta_text_color,
    description_text_color
  });

  const hideSection = useMemo(() => {
    const shouldHideUnlessAdmin = subscription_level === 'Admins' && !isAdmin;
    const shouldHideUnlessSubscriber = subscription_level === 'Subscribers' && !isSubscriber;
    const shouldHideUnlessFreeOrGuest = subscription_level === 'Free Users/Guests' && isSubscriber;

    return (
      shouldHideUnlessAdmin ||
      shouldHideUnlessSubscriber ||
      shouldHideUnlessFreeOrGuest ||
      isUserStateLoading
    );
  }, [isAdmin, isSubscriber, isUserStateLoading, subscription_level]);

  if (hideSection) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.headerStyle}>{offer_title}</Typography>
      <div className={classes.ticketContainer}>
        <div className={classes.containerStyle}>
          <Grid className={classes.leftCutIn}>
            <img src={isMobile ? mobileLeftImg : leftCutInImg} alt="cut in" />
          </Grid>
          <div className={classes.innerContainer}>
            <Grid className={classes.logoStyle}>
              <img src={isMobile ? mobileLogo : logo} alt="Logo" />
            </Grid>
            <img src={isMobile ? dottedLineMobileImg : dottedLineImg} alt="dotted line" />
            <Grid className={classes.offerContainer}>
              <Typography className={classes.offerStyle}>{offer_name}</Typography>
              <RichText html={offer_description.html} externalClassName={classes.description} />
              <Typography className={classes.descriptionStyle}>
                {' '}
                Use coupon code{' '}
                <Button component={Link} to={cta_url} className={classes.codeStyle}>
                  {coupon_code}
                </Button>{' '}
              </Typography>
            </Grid>
            {!isMobile && (
              <>
                <Grid>
                  <img src={dottedLineImg} alt="dotted line" />
                </Grid>
                <Grid>
                  <Button className={classes.checkoutButton} component={Link} to={cta_url}>
                    {cta_text}
                  </Button>
                </Grid>
              </>
            )}
          </div>
          <Grid className={classes.rightCutIn}>
            <img src={isMobile ? mobilRighttImg : rightCutInImg} alt="cut in" />
          </Grid>
        </div>
      </div>
    </Box>
  );
};

CouponComponent.propTypes = {
  offer_title: PropTypes.string.isRequired,
  offer_name: PropTypes.string.isRequired,
  offer_description: PropTypes.shape({ html: PropTypes.string }).isRequired,
  coupon_code: PropTypes.string.isRequired,
  cta_text: PropTypes.string,
  cta_url: PropTypes.string,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  description_text_color: PropTypes.string,
  cta_background_color: PropTypes.string,
  cta_text_color: PropTypes.string,
  subscription_level: PropTypes.string
};
CouponComponent.defaultProps = {
  cta_text: 'checkout',
  cta_url: '/subscribe',
  background_color: '#020928',
  text_color: '#fff',
  description_text_color: '#fff',
  cta_background_color: '#fff',
  cta_text_color: '#001730',
  subscription_level: 'Everyone'
};

export default CouponComponent;
